@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributes {
    &-ExpandableContentContent {
        columns: 1;
    }

    &-Content {
        &_isLoading {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .TextPlaceholder {
                display: block;

                &::after {
                    display: block;
                    line-height: 2rem;
                    width: 78%;
                }

                &:nth-of-type(1) {
                    &::after {
                        width: 38%;
                        margin-block-end: 10px;
                    }
                }
            }
        }
    }

    &-ContentHeading {
        margin: 32px 0 16px;
    }

    &-Attributes {
        margin: auto;
        max-width: 100%;
        width: 100%;
        margin-block-end: 37px;

        @include mobile {
            margin-block-end: 0;
        }

        dt,
        dd {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            padding: 12px 16px;
            background: rgba(232, 233, 233, 0.5);

            &:nth-of-type(2n) {
                background: transparent;
            }
        }

        dt {
            justify-content: flex-start;
            font-weight: 400;
        }

        dd {
            justify-content: flex-start;
            font-weight: 300;
        }

        a {
            color: $color-primary;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &-ValueLabel,
    &-AttributeLabel {
        border-bottom: none;
    }
}

@include tabletAndAbove {
    .ProductAttributes {
        &-Attributes dt {
            justify-content: flex-end;
        }

        &-ContentHeading {
            margin: 0 0 40px;
        }
    }
}
